import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import { FAQ_DISCLAIMER } from '../constants';
import styles from './styles.scss';

export const Compliance = ({ contactFormLink }) => {
  const openChat = () => {
    HTKustomerChat.open();
  };
  const cardHeaderStyles = 'h5 marginBottom-tiny1';
  const cardTextStyles = 'p1 n700 marginBottom-small';
  const cardLinkStyles = cn(styles.link, 'p1 teal text-weight-med');

  return (
    <div className={styles.container}>
      <PanelV2 className={styles.panel} noShadow>
        <h1 className="h3 n900 text-weight-med">Contact us</h1>

        <div className={styles.contactGrid}>
          <div className={styles.contactCard}>
            <h2 className={cardHeaderStyles}>Chat with us</h2>
            <p className={cardTextStyles}>We're available to help 24/7</p>
            <button onClick={openChat} className={cn('plainButton', cardLinkStyles)} type="button">
              Chat now
            </button>
          </div>

          <div className={styles.contactCard}>
            <h2 className={cardHeaderStyles}>Email us</h2>
            <p className={cardTextStyles}>We'll respond within 24 hours</p>
            <a
              href={contactFormLink}
              className={cardLinkStyles}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Send us a message via our contact form (opens in new tab)"
            >
              Send message
            </a>
          </div>

          <div className={styles.contactCard}>
            <h2 className={cardHeaderStyles}>Text us*</h2>
            <p className={cardTextStyles}>We're available to help 24/7</p>
            <a href="sms:1-414-417-1629" className={cardLinkStyles}>
              1-414-417-1629
            </a>
          </div>

          <div className={styles.contactCard}>
            <h2 className={cardHeaderStyles}>Call us</h2>
            <p className={cardTextStyles}>Available 4am-1pm PST, all week</p>
            <a href="tel:1-800-640-9005" className={cardLinkStyles}>
              1-800-640-9005
            </a>
          </div>
        </div>

        <p className="caption n700">{FAQ_DISCLAIMER}</p>
      </PanelV2>
    </div>
  );
};

Compliance.propTypes = {
  contactFormLink: PropTypes.string.isRequired,
};
