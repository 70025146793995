/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { SplitIO, splitioConstants, SplitTreatmentToggle } from 'src/components/SplitIO';
import AsyncRoute from 'src/components/AsyncRoute';
import TwoFactorPin from 'src/containers/2FA';

// Constants
import { COMCAST_SALES } from 'src/containers/ComcastPage/constants';
import { SRC_TYPES } from 'src/containers/SiteFAQ/constants';
import { PARTNERS } from './constants/common';
import { XFINITY_VANITY_URLS } from './constants/partner';
import { LANDING_PAGES } from './containers/LandingPage/constants';
import ROUTES from './routes';

// Pages
import AppBase from './containers/AppBase/index';
import HomePage, { HomePageIframe } from './containers/HomePage';
import ServicePage from './containers/ServicePage';
import ServicesPage from './containers/ServicesPage';
import CartPage from './containers/CartPage';
import SiteFAQ from './containers/SiteFAQ';
import GeekSquadPage from './containers/GeekSquadPage';
import LocationPage from './containers/LocationPage';
import LandingPage from './containers/LandingPage';
import HomeHubCategory from './containers/HomeHub';

// Not using AsyncRoute
import IframeProductsPage, {
  IframeProductPageRoutes,
} from './containers/ProductPage/IframeProductPage';

import IframeTechProductsPage, {
  IframeTechProductPageRoutes,
} from './containers/DirectBooking/TechProductsPage';

// Not using AsyncRoute
import IframeBundlesPage from './containers/ProductPage/IframeBundlesPage';

// Not using AsyncRoute
import IframeCategoryPage, {
  IframeCategoryPageRoutes,
} from './containers/SkuCategoryPage/CategoryIframe';

import IframeComcastPage, {
  IframeXfinityPageRoutes,
  PATHS_TO_IFRAME_XFINITY,
} from './containers/ComcastPage/ComcastIframe';

const REDIRECTS = [
  { from: '/optimumnest', to: '/alticenest' },
  { from: '/suddenlinknest', to: '/alticenest' },
];

/*
  Use AsyncRoute on pages that do not need to be optimized.
*/
const App = (appProps) => (
  <AppBase>
    <SplitIO>
      <Switch>
        <Route
          exact
          path={ROUTES.HOME}
          component={() => (
            <SplitTreatmentToggle
              splitName={splitioConstants.SPLITIONAME_HOMEPAGE_IFRAME}
              matchTreatment={splitioConstants.OFF}
              control
            >
              <HomePage />
              <HomePageIframe />
            </SplitTreatmentToggle>
          )}
        />
        <Route exact path={['/services', '/tech-support/services']} component={ServicesPage} />
        <Route exact path="/tech-support/:seoName" component={ServicePage} />
        <Route
          path={IframeCategoryPageRoutes}
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            const { pathname } = props.location;
            return <IframeCategoryPage src={pathname} />;
          }}
        />
        <Route
          path={[...IframeXfinityPageRoutes, ...XFINITY_VANITY_URLS]}
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            const { pathname, search } = props.location;
            return <IframeComcastPage src={pathname} queryParams={search} />;
          }}
        />
        <Route exact path={ROUTES.CART} component={CartPage} />
        <Route exact path="/cart/pin" component={TwoFactorPin} />
        <Route exact path="/cart/:token" component={CartPage} />
        <Route exact path="/faq" component={SiteFAQ} />
        <Route exact path="/homehub/:category?" component={HomeHubCategory} />
        <Route exact path={ROUTES.GEEK_SQUAD} component={GeekSquadPage} />
        <Route
          path="/us/:locationPath*"
          component={(props) => {
            if (process.env.NODE_ENV === 'development') {
              return <LocationPage {...props} />;
            }

            const globalWindow = process.browser ? window : { location: { reload: () => {} } };
            globalWindow.location.reload();

            return false;
          }}
        />

        <Route path={`/landings/${PARTNERS.XFINITY}`}>
          <Redirect to={PATHS_TO_IFRAME_XFINITY.DEFAULT} />
        </Route>

        <Route
          path={`/landings/${PARTNERS.XFINITY}`}
          component={() => {
            const globalWindow = process.browser ? window : {};
            globalWindow.location = `/${PARTNERS.XFINITY}`;
            return null;
          }}
        />
        <Route
          path="/landings/comcastbusiness"
          component={() => {
            const globalWindow = process.browser ? window : {};
            globalWindow.location = '/comcastbusiness';
            return null;
          }}
        />

        <Route
          path={['/landings/ahstvmounting', '/landings/ahs']}
          component={() => <Redirect to={'/ahs'} />}
        />

        <Route
          path={'/ahs'}
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/PartnerIframePage/AHSPartnerPage')}
              />
            );
          }}
        />

        <Route exact path="/landings/:name" component={LandingPage} />
        {LANDING_PAGES.map(([path, name]) => (
          <Route
            key={path}
            exact
            path={path}
            component={(props) => <LandingPage {...props} name={name} />}
          />
        ))}
        {REDIRECTS.map(({ from, to }) => (
          <Route key={`${from}-${to}`} exact path={from} component={<Redirect to={to} />} />
        ))}
        {/*
         ******************* Start of AsyncRoute Usage *************************
         */}
        <Route
          exact
          path="/plans/:name"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Plans/PlanPageAlternate')}
            />
          )}
        />
        <Route
          exact
          path="/meet-our-techs"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/MeetOurTechsPage')} />
          )}
        />
        <Route
          exact
          path="/customer-reviews"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/CustomerReviewsPage')} />
          )}
        />
        <Route
          exact
          path={ROUTES.TOS}
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/TOSPages')} />
          )}
        />
        <Route
          exact
          path="/sns"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/SafetyandSupportPage')}
            />
          )}
        />
        <Route
          exact
          path="/cancellation-policy"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/CancellationPolicyPage')}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.PRIVACY}
          component={(props) => (
            <AsyncRoute
              props={{
                ...props,
                src: SRC_TYPES.PRIVACY_POLICY,
                callerPage: 'privacy-policy',
                title: 'Privacy Policy',
              }}
              loadingPromise={import('./containers/SiteFAQ')}
            />
          )}
        />
        <Route
          exact
          path="/contact-us"
          component={(props) => (
            <AsyncRoute
              props={{ ...props, src: SRC_TYPES.CONTACT_US }}
              loadingPromise={import('./containers/SiteFAQ')}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.MICROSOFT_SUPPORT}
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/MicrosoftPage/SupportPage')}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.MICROSOFT_WINDOWS_SUPPORT}
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/MicrosoftPage/WindowsSupportPage')}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.SIGN}
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/SignPage')} />
          )}
        />
        <Route
          exact
          path={ROUTES.CREATE_ACCOUNT}
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/CreateAccountPage')} />
          )}
        />
        <Route
          exact
          path={ROUTES.LOGIN}
          component={(props) => {
            return (
              <AsyncRoute props={props} loadingPromise={import('./containers/LoginPage/index')} />
            );
          }}
        />
        <Route
          exact
          path={ROUTES.CLIENTS_REGISTRATION}
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/RegistrationPage/index')}
            />
          )}
        />
        <Route
          exact
          path="/account/orders/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/OrderPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/orders/:orderId/:status"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/OrderPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/order-summary/:orderToken"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/OrderPage/PublicPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/order-summary/:orderToken/:status"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/OrderPage/PublicPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/palo-alto-orders/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/BookingPagePaloAltoNetworks/OrderPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/palo-alto-orders/:orderId/:status"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/BookingPagePaloAltoNetworks/OrderPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/palo-alto-order-summary/:orderToken"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import(
                  './containers/BookingPagePaloAltoNetworks/OrderPage/PublicPage/index'
                )}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/palo-alto-order-summary/:orderToken/:status"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import(
                  './containers/BookingPagePaloAltoNetworks/OrderPage/PublicPage/index'
                )}
              />
            );
          }}
        />
        <Route
          exact
          path="/orderstatus"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/GetOrderStatusPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/my-pros"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/MyProsPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/scorecard"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/HomeScoreCardPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/devices"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/DevicesPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/Account/OrdersPage')} />
          )}
        />
        <Route
          exact
          path="/account/profile"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Account/AccountPage/index')}
            />
          )}
        />
        <Route
          exact
          path="/account/plan"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/PlanPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/plan/:details"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/PlanPage/MemberBenefitsMobileView')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/payment"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Account/PaymentPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/account/tech-location/:orderId"
          component={({ match }) => {
            const { params } = match;
            return <Redirect to={`/account/orders/${params?.orderId ?? ''}`} />;
          }}
        />
        <Route
          exact
          path={ROUTES.RESET_PASSWORD}
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Account/ResetPasswordPage/index')}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.PLANS}
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Plans/MonthlyPlanPage')}
            />
          )}
        />
        <Route
          exact
          path="/clients/update-password"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Account/UpdatePasswordPage/index')}
            />
          )}
        />
        <Route
          exact
          path="/clients/orders/confirmation/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/OrderConfirmationPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/subscription/confirmation"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/SubscriptionConfirmationPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/subscription-gift/confirmation"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/SubscriptionGiftingConfirmationPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/palo-alto-networks/confirmation/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import(
                  './containers/BookingPagePaloAltoNetworks/ConfirmationPage/index'
                )}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/order-reschedule/:orderToken"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/ReschedulingPage/PublicPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/orders/reschedule/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/ReschedulingPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/palo-alto-order-reschedule/:orderToken"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                // loadingPromise={import('./containers/ReschedulingPage/PublicPage/index')}
                loadingPromise={import(
                  './containers/BookingPagePaloAltoNetworks/ReschedulingPage/PublicPage/index'
                )}
              />
            );
          }}
        />
        <Route
          exact
          path="/clients/palo-alto-orders/reschedule/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                // loadingPromise={import('./containers/ReschedulingPage/index')}
                loadingPromise={import(
                  './containers/BookingPagePaloAltoNetworks/ReschedulingPage/index'
                )}
              />
            );
          }}
        />
        <Route
          exact
          path={`${ROUTES.ADD_SKU}:skuId/recommend`}
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Recommendations/ConfirmAddedPage')}
            />
          )}
        />
        <Route
          path={`${ROUTES.ADD_SKU}:skuId/:question?`}
          component={(props) => {
            return (
              <AsyncRoute props={props} loadingPromise={import('./containers/AddSkuPage/index')} />
            );
          }}
        />
        <Route
          exact
          path="/plans/:name/thank-you"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Plans/ThankYouPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/redeem"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/Plans/RedeemPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/complete-partner-order"
          component={(props) => {
            return (
              <AsyncRoute
                props={{ ...props, isConfirmation: true }}
                loadingPromise={import('./containers/FinishPartnerOrderPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/cart/booking/sku"
          component={(props) => {
            return (
              <AsyncRoute
                props={{ ...props, isConfirmation: true }}
                loadingPromise={import('./containers/AddSkuPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path={[ROUTES.BOOKING_PALO_ALTO_NETWORKS, `${ROUTES.BOOKING_PALO_ALTO_NETWORKS}/:stage`]}
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/BookingPagePaloAltoNetworks/index')}
              />
            );
          }}
        />
        <Route
          exact
          path={`${ROUTES.BOOKING}:stage`}
          component={(props) => {
            return (
              <AsyncRoute props={props} loadingPromise={import('./containers/BookingPage/index')} />
            );
          }}
        />
        <Route
          exact
          path="/complete_order/set_password" // This URL is used to redeem API orders for new clients
          component={(props) => {
            return (
              <AsyncRoute props={props} loadingPromise={import('./containers/FinishOrder/index')} />
            );
          }}
        />
        <Route
          exact
          path="/set_password" // This URL is primarily used to redeem SHP gift orders, but is a generic Create Password page
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/CreatePasswordPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/complete_order"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/FinishOrder/FinishOrder')}
              />
            );
          }}
        />
        <Route
          exact
          path="/rating/:id/:stars"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/CustomerSurvey/index')}
              />
            );
          }}
        />
        <Route
          exact
          path={`${ROUTES.DIRECT_BOOKING}/:techId`}
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/DirectBooking/TechProfilePage')}
              />
            );
          }}
        />
        <Route
          exact
          path={`${ROUTES.DIRECT_BOOKING}/:techId/services`}
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/DirectBooking/TechSkusPage')}
              />
            );
          }}
        />
        <Route
          exact
          path="/xfinity-referral"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/ComcastPage/XfinityTechPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/smb-sales"
          component={(props) => {
            return (
              <AsyncRoute
                props={{ ...props, [COMCAST_SALES.SMB_DIRECT]: true }}
                loadingPromise={import('./containers/ComcastPage/XfinityTechPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/subscription-gift/:stage"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/SubscriptionGiftingPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/subscription-gift"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/SubscriptionGiftingPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/zip-code-check"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/ZipCodeValidationPage')}
            />
          )}
        />
        <Route
          path={IframeProductPageRoutes}
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            const { pathname } = props.location;
            return <IframeProductsPage src={pathname} />;
          }}
        />
        {/* Direct Booking iframe ProductsPage */}
        <Route
          path={IframeTechProductPageRoutes}
          component={(props) => {
            const { pathname } = props.location;
            return <IframeTechProductsPage src={pathname} />;
          }}
        />
        <Route
          path="/products/bundles"
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            const { pathname } = props.location;
            return <IframeBundlesPage src={pathname} />;
          }}
        />
        <Route
          path="/cove"
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/PartnerIframePage/CovePartnerPage')}
              />
            );
          }}
        />
        <Route
          path="/alder"
          component={(props) => {
            // eslint-disable-next-line react/prop-types
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/PartnerIframePage/AlderPartnerPage')}
              />
            );
          }}
        />
        <Route
          exact
          path="/products/:id"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/ProductPage')} />
          )}
        />
        <Route
          exact
          path="/speed"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/SpeedTestPage')} />
          )}
        />
        <Route
          exact
          path="/target"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/FinishOrder/PINRedemption/Target')}
            />
          )}
        />
        <Route
          exact
          path="/unsubscribe/:token"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/UnsubscribePage')} />
          )}
        />
        <Route
          exact
          path="/prepaid"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/FinishOrder/PINRedemption/Qvc')}
            />
          )}
        />
        <Route
          path="/fieldsales"
          component={(props) => (
            <AsyncRoute props={props} loadingPromise={import('./containers/FieldSales')} />
          )}
        />
        <Route
          path="/techs-service/counteroffer/:token"
          component={(props) => (
            <AsyncRoute
              props={props}
              loadingPromise={import('./containers/Techs/CounterofferPage')}
            />
          )}
        />
        <Route
          exact
          path="/palo-alto-rating/:id"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import(
                  './containers//BookingPagePaloAltoNetworks/CustomerSurvey/CustomerSurvey'
                )}
              />
            );
          }}
        />
        {/* ------------ EV ROUTES ------------------ */}
        <Route
          exact
          path="/ev/customer-info"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/CustomerInfoPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/add-sku/:skuId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/SkuQuestionsPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/cart/booking/availability"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/SchedulingPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/orders/reschedule/:orderId"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/ReschedulingPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/order-reschedule/:orderToken"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/ReschedulingPage/PublicPage')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/cart/booking/payment"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/PaymentPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/cart/booking/review"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/ReviewPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/portal"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/PortalPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/portal/:workflowToken"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/PortalPage/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/tech/quote"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/QuoteGenerator/index')}
              />
            );
          }}
        />
        <Route
          exact
          path="/ev/tech/quote/confirmation"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import(
                  './containers/EV/QuoteGenerator/QuoteGenerator.ConfirmationPage'
                )}
              />
            );
          }}
        />
        {/* NOTE: this must go last to capture the iframes for landing pages */}
        <Route
          exact
          path="/ev/:evSkuType"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import('./containers/EV/IframeLandingPage/index')}
              />
            );
          }}
        />
        {/* ------------ END EV ROUTES ------------------ */}
        {/* ------------ SUBSCRIPTION ENROLLMENT ROUTES ------------------ */}
        <Route
          exact
          path="/enrollment/:partnerName"
          component={(props) => {
            return (
              <AsyncRoute
                props={props}
                loadingPromise={import(
                  './containers/SubscriptionEnrollment/MemberValidationPage/index'
                )}
              />
            );
          }}
        />
        {/* ------------ END SUBSCRIPTION ENROLLMENT ROUTES ------------------ */}
        <Route
          component={(props) => {
            appProps.context.status = '404';
            return (
              <AsyncRoute
                props={{ ...props, code: 404 }}
                loadingPromise={import('./containers/ErrorPage')}
              />
            );
          }}
        />
      </Switch>
    </SplitIO>
  </AppBase>
);

export default App;
