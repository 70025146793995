import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openLoginModal } from 'src/containers/LoginPage/Modal/actions';
import {
  plansPath,
  snsPath,
  locationsRootPath,
  faqPath,
  loginPath,
  accountPath,
  getOrderStatusPath,
  productsIframeBasePath,
  homehubPath,
} from 'src/utils/paths';
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import { prevented } from 'src/utils/event';
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import HT_INFO from 'src/constants/hellotech';
import MenuTemplate from '../MenuTemplate';
import { LOGIN_MODAL_OPTIONS } from 'src/containers/LoginPage/Modal/constants';
import { useIsPasswordlessCheckoutEnabled } from 'src/containers/Account/AccountPage/AccountPage.hooks';

const PrimaryMenu = ({ user, setCategoryMenuVisibleState, hideMembership, logout, isMobile }) => {
  const dispatch = useDispatch();
  const isPasswordlessCheckoutEnabled = useIsPasswordlessCheckoutEnabled();

  const headerItems = [
    {
      text: 'Services',
      onClick: prevented(() => {
        setCategoryMenuVisibleState(true);
      }),
    },
    ...(!HIDE_PRODUCT_CONNECTED_HOME
      ? [
          {
            text: 'Products',
            link: productsIframeBasePath(),
          },
        ]
      : []),
    ...(!hideMembership
      ? [
          {
            text: 'Membership',
            link: plansPath(),
          },
        ]
      : []),
    ...(!HIDE_PRODUCT_CONNECTED_HOME
      ? [
          {
            text: 'My Connected Home',
            link: homehubPath(),
            showBadge: true,
          },
        ]
      : []),
    {
      text: 'Partner with Us',
      link: HT_INFO.siteLinks.partnerships.url,
      isExternalLink: true,
    },
    {
      text: 'FAQ',
      link: faqPath(),
    },
  ];

  const openLoginModalAction = () => {
    dispatch(openLoginModal(false, { [LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS]: true }));
  };

  const getMyAccountItemConfig = () => {
    const isLoggedIn = Boolean(user);

    if (isPasswordlessCheckoutEnabled) {
      if (isLoggedIn) {
        return { link: accountPath() };
      }
      return { onClick: openLoginModalAction, hideIcon: true };
    }

    return { link: isLoggedIn ? accountPath() : loginPath() };
  };

  const myAccountItemConfig = getMyAccountItemConfig();

  const secondaryItems = [
    {
      text: 'My Account',
      ...myAccountItemConfig,
    },
    {
      text: 'Track My Order',
      link: getOrderStatusPath,
    },
    {
      text: 'Locations',
      link: locationsRootPath(),
    },
    {
      text: 'Safety & Support',
      link: snsPath(),
    },
    {
      text: 'Blog',
      link: HT_INFO.siteLinks.blog.url,
      isExternalLink: true,
    },
    {
      text: 'Contact Us',
      onClick: prevented(() => HTKustomerChat.open()),
      hideIcon: true,
    },
    ...(user && isMobile
      ? [
          {
            text: 'Sign Out',
            onClick: prevented(() => logout()),
            hideIcon: true,
          },
        ]
      : []),
  ];

  return <MenuTemplate headerItems={headerItems} secondaryItems={secondaryItems} />;
};

PrimaryMenu.propTypes = {
  user: PropTypes.object,
  setCategoryMenuVisibleState: PropTypes.func.isRequired,
  logout: PropTypes.func,
  hideMembership: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default React.memo(PrimaryMenu);

/*
  Minor Refactor TODO:
  We should bring in isMobile and logout directly like:
  import { logout } from 'src/containers/AppBase/actions';
  import { isMobileSelector } from 'src/selectors/resolution';
  import {useDispatch, useSelector} from 'react-redux'

  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileSelector);

  in line 89 :
  onClick: prevented(() => dispatch(logout())),

  Remove all instances of logout and isMobile from
  src/components/Sidebar/index.js,
  src/components/Sidebar/Nav/Nav.js,
  src/components/Sidebar/selectors.js
*/
